// in src/posts.js
import React, { Fragment, useState, useCallback , useEffect, createRef} from 'react';

import {
  Admin,
  Resource,
  List,
  ListProps,
  SimpleForm,
  TextInput,
  DateTimeInput, required, ReferenceInput, SelectInput, useRefresh, useCreate,
    useNotify,
    useUpdate,
    useRedirect, BooleanInput , TopToolbar, CreateButton, CheckboxGroupInput , ReferenceArrayInput, Button, TextField, SimpleShowLayout, DeleteButton, FormDataConsumer, DateInput,
    useDataProvider, FormWithRedirect, useRecordContext, ReferenceField, FormTab,
    TabbedForm,ArrayField, Datagrid, ChipField, SingleFieldList, downloadCSV , RefreshButton, ShowButton, linkToRecord, AutocompleteInput, useSelectedValues, Edit 
} from "react-admin";

import jsonExport from 'jsonexport/dist';

import RichTextInput from 'ra-input-rich-text';

import { UserAvatarField , UserFullNameTxt , RenderAge, RenderDate, RenderGender, RenderBoolean, ChipFieldUserName, UserInput } from '../components/User';

import { apiUrl, apiOrigin, apiDefaultLanguage, targetsResources, targets, timeZone } from './../common/constants';
import { BookingsList } from './../components/Bookings';
import { Empty } from './../components/Empty';

import DownloadIcon from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ExportButton } from './../components/ExportButton';

import { CityInput } from './../components/Inputs';

import { parse } from "query-string";
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Create, SaveButton, Toolbar } from 'react-admin';
import CustomConfirm from 'ra-custom-confirm';
import { useForm, useFormState } from "react-final-form";
import Save from '@material-ui/icons/Save';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { RadioButtonGroupInput } from 'react-admin';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import IconCancel from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CompleteCalendar } from "@react-admin/ra-calendar";
import caLocale from "@fullcalendar/core/locales/ca";
import Grid from '@mui/material/Grid';
import { Chip } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { DocsList, DocsListShow } from './docs';

import { useHistory } from 'react-router-dom';

import { i18nProvider, useTranslate  } from '../common/language';
import { validateMaxLength, validateInteger, validateDouble, validateEmail  } from './../components/Validator';
import { modules, modulesOptions  } from '../common/constants';
import { subscriptionModule, dateParser, toIsoString  } from '../common/utils';


const ActivitiesFilter = props => {
    const translate = useTranslate();
    return (<ReferenceInput                          
        label={translate('events.activity')}
        source="activities_id" 
        reference="activities" 
        filter={{ }} 
        perPage={1000}
        sort={{ field: 'priority', order: 'ASC' }}
        alwaysOn
        className="filterCalendar"
    >
       <SelectInput optionText="name" optionValue="id" resettable />
    </ReferenceInput>);
};

const ClassroomsFilter = props => {
    const translate = useTranslate();
    return (<ReferenceInput                          
        label={translate('events.classroom')}
        source="classrooms_id" 
        reference="classrooms" 
        filter={{ associations_id: localStorage.getItem('associations_id'), onlyForEvents: true }}
        perPage={1000}
        sort={{ field: 'priority', order: 'ASC' }}
        alwaysOn
        className="filterCalendar"
        
    >
        <SelectInput optionText="name" optionValue="id" resettable />
    </ReferenceInput>);
};

const PublicFilter = props => {
    const translate = useTranslate();
    return (<SelectInput className="calendar-filter-select" label={translate('events.is_public_filter')} source="is_public" choices={[{id:1,name:translate('common.yes')},{id:0,name:translate('common.no')}]} resettable />);
};

const ShowFacFilter = props => {
    const translate = useTranslate();
    if (subscriptionModule(modules.CALENDAR,modulesOptions.CALENDAR_FAC)) 
        return (<SelectInput className="calendar-filter-select" label={translate('events.show_fac_filter')} source="show_fac" choices={[{id:1,name:translate('common.yes')},{id:0,name:translate('common.no')}]} resettable />);
    else
        return null;
};

const CalendarFilters = [ 
    <ActivitiesFilter alwaysOn />, 
    <ClassroomsFilter alwaysOn />,
    <PublicFilter alwaysOn />,
    <ShowFacFilter alwaysOn />,
];

const EventsActions = (props) => {
    
    const link = createRef();
    const translate = useTranslate();
    const dataProvider = useDataProvider();    
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);
        
    const exportClick = () => {
        setShowDialog(true);
    }
    const handleCloseClick = () => {
        setShowDialog(false);
    }; 
        
    const handleSubmit = async values => {
        
        let headers = new Headers({ Accept: 'application/json' });
        headers.set('Access-Control-Allow-Origin', apiOrigin );
        headers.set('Authorization', localStorage.getItem('auth'));
        headers.set('Accept-Language', apiDefaultLanguage);
        headers.set('Role', localStorage.getItem('active_permission'));

        values.page=0;
        values.size=100000;
        values.sort='dateStart,ASC';

        const url = new URL(apiUrl + '/events/export');
        url.search = new URLSearchParams(values);

        const result = await fetch(url , { 
          headers: headers
        });

        const blob = await result.blob();
        const href = window.URL.createObjectURL(blob);
        link.current.download = translate('events.pdf_filename');
        link.current.href = href;
        link.current.click();
        notify(translate('common.download_ok'), 'success');
        setShowDialog(false);
    };  
        
    let filterExport = {
        associations_id : localStorage.getItem('associations_id'),        
    }
    
    var now = new Date();      
    var daysLater = new Date();   
    daysLater.setMinutes(daysLater.getMinutes()+6*24*60);

    filterExport.start_gte = now.toISOString(now);
    filterExport.start_lte = daysLater.toISOString(daysLater);        
            
    if ( props.filterValues.activities_id ) filterExport.activities_id = props.filterValues.activities_id;
    if ( props.filterValues.classrooms_id ) filterExport.classrooms_id = props.filterValues.classrooms_id;
    if ( props.filterValues.is_public !=null ) filterExport.is_public = props.filterValues.is_public;
    if ( props.filterValues.show_fac !=null ) filterExport.show_fac = props.filterValues.show_fac;
                
    /* Per exportació en CSV (client) */
    // <ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={true} exporter={exporter} sort={{ field: 'dateStart', order: 'DESC' }} />  
    
    return (
    <>
    <TopToolbar>        
        <CreateButton/>   
        <Button onClick={exportClick} label={translate('ra.action.export')} ><DownloadIcon /></Button>     
        <ShowButton 
            component={Link}
            to={{
                pathname: `/public/events/list?associations_id=${localStorage.getItem('associations_id')}`
            }} 
            label={translate('public_events.view_web')}
            target="_blank"
        />
        <RefreshButton label=""/>
    </TopToolbar>
    <Dialog
            fullWidth
            open={showDialog}
            onClose={handleCloseClick}
            disableEnforceFocus
        >
            <DialogTitle>{translate('events.export_title')}</DialogTitle>

            <FormWithRedirect
                resource="events"
                save={handleSubmit}
                initialValues={ filterExport }
                render={({
                    handleSubmitWithRedirect,
                    pristine,
                    saving
                }) => (
                    <>
                        <DialogContent>
                            <br />
                            {translate('events.export_form_text')}
                            <Grid container spacing={2}>
                                <Grid item xs={6}>     
                                    <DateInput fullWidth source="start_gte" validate={required()} label={translate('events.export_form_from')} />
                                </Grid> 
                                <Grid item xs={6}>     
                                    <DateInput fullWidth source="start_lte" validate={required()} label={translate('events.export_form_to')} />
                                </Grid> 
                            </Grid>                                                     
                        </DialogContent>
                        <DialogActions>
                            <Button label="ra.action.cancel" onClick={handleCloseClick}>
                                <IconCancel />
                            </Button>
                            <a role='button' ref={link} style={{ textDecoration: 'none', display: 'none' }} target="_blank"></a>
                            <SaveButton
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon={<PictureAsPdfIcon/>}
                                label={translate('ra.action.export')}
                            />
                            
                        </DialogActions>
                    </>
                )}
            />
        </Dialog>
    </>
    )
};

/*
SISTEMA VELL EXPORTACIÓ DES DE CLIENT (al final ho fem des de servidor, en un PDF)
const exporter = (items, translate, notify) => {
        
    const eventsForExport = items.map(event => {
        var eventForExport = {
            id : event.id,
            title : event.title, 
            date_start : event.start.substr(0,10) + ' ' + event.start.substr(11,5), 
            date_finish : event.end.substr(0,10) + ' ' + event.end.substr(11,5), 
            classrooms_name: event.classrooms_name,
            activities_name: event.activities_name,
            is_public: event.is_public ? translate('common.yes') : translate('common.no'),
            show_fac: event.show_fac ? translate('common.yes') : translate('common.no')  
        }
        return eventForExport;
    });
    jsonExport(eventsForExport, {
        headers: ['id', 'title', 'date_start', 'date_finish', 'classrooms_name', 'activities_name','is_public','show_fac'],
        rename: [translate('events.exporter_header_id'), translate('events.exporter_header_title'), translate('events.exporter_header_date_start'), translate('events.exporter_header_date_finish'), translate('events.exporter_header_classrooms_name'), translate('events.exporter_header_activities_name'), translate('events.exporter_header_is_public')],        
        
    }, (err, csv) => {
        const csv_new = '\uFEFF' + csv;
        downloadCSV(csv_new, translate('events.exporter_file_name') ); 
        notify(translate('common.download_ok'),"success");
    });
};
*/


const converter = (event: any): EventInput => ({
  id: String(event.id),
  title: event.title,
  start: event.start,
  end: event.end,
  backgroundColor: event.color,  
  borderColor: event.color,
  editable: true,
  groupId: event.is_public ? '/#/public/events?events_id='+event.id : ''
});


export const EventsList = (props: ListProps) => {    
    
    const translate = useTranslate(); 
    const [createDialog, setCreateDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const notify = useNotify();
    var history = useHistory();
    
    const handleFailure = (error) => {   
        notify(error.message, 'error');
    };
    
    var handleEventClick = function (clickInfo) {                        
        if (clickInfo.jsEvent.target.tagName === 'svg' || clickInfo.jsEvent.target.tagName === 'path')
        {
            // Si cliquem sobre la icona de l'esdeveniment, entenem que volem veure l'event (cal buscar una millor solució)
            window.open(clickInfo.event.groupId, '_blank');
        }
        else
        {
            // Sinó, entenem que volem editar l'event        
            history.push(linkToRecord("events", clickInfo.event.id));
        }
    };
    
    return (
    <CompleteCalendar 
      {...props}
      ListProps={{
        perPage: 1000,
        filter: {associations_id : localStorage.getItem('associations_id') } , 
        filters : CalendarFilters,
        actions: <EventsActions/> ,
        basePath: "events"
      }}
      CalendarProps={{
        locale: caLocale,
        timeZone: timeZone,
        mutationMode:"pessimistic",
        scrollTime: "08:00:00",
        slotDuration: '00:30:00',
        slotLabelInterval: "01:00",
        allDaySlot: false,
        nowIndicator: true,
        height: 700, 
        displayEventTime: false,
        editable:true,
        basePath: "events",
        convertToEvent: converter,
        eventClick: handleEventClick,
        eventContent : (eventContent) => {
            return(
                <Box display="flex" alignItems="center" justifyContent="space-between" className="events-calendar-icon">
                        <div>{eventContent.event.title}</div>
                        {eventContent.event.groupId && eventContent.event.groupId.length > 0 ? <VisibilityIcon /> : null }                
                </Box>
                );}            
        ,
        /*
        Usem el camp groupId per passar la URL per consultar a la web l'esdeveniment.
        */
       
        /*select: handleDateClick,*/
        /*eventDrop: function(info) {
            alert('eventDrop');            
        },
        eventDragStop: function(info) {
            alert('eventDragStop');
        },
        eventResize: function(info) {
            alert('eventResize');
        },
        eventResizeStop: function(info) {
            alert('eventResizeStop');
        },*/
      
      }}
      CreateDialogProps={{
        title: translate('events.create'),
        mutationMode:"pessimistic",
        fullWidth: true,
        maxWidth: 'lg',
      }}
      EditDialogProps={{
        title: translate('events.edit'),
        edit: true,
        mutationMode:"pessimistic",
        fullWidth: true,
        maxWidth: 'lg',
      }}
      CreateDialogForm={<FormEvent />}      
      EditDialogForm={<FormEvent />}
      onFailure={handleFailure}     
    >        
    </CompleteCalendar> ); 
}


const EventToolbar = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const translate = useTranslate(); 
    const dataProvider = useDataProvider();
    
    return (
        <Toolbar {...props}>
            
            {props.record && props.record.repetitive_id && props.record.repetitive_id > 0 ?
                <SaveButtonWithEditionMode />
                :
                <SaveButton {...props} transform={ (data) => {
                    const data1 = data;
                    if (data1.classrooms_id > 0)
                    {
                        /* "Nyapa" per a recuperar el color de la classroom. Es necessita per a actualitzar el calendari amb el nou esdeveniment */
                        let color = localStorage.getItem('classrooms_color') ? localStorage.getItem('classrooms_color') : null;
                        return ( {...data1 , color: color });            
                    }
                    else
                        return ( {...data1 , color: null });
                }} />
            } 
            {props.record && props.record.repetitive_id && props.record.repetitive_id > 0 ?
                <DeleteButtonWithEditionMode />
                :
                <DeleteButton undoable={false} className="event_button_delete" />
            } 
            
        </Toolbar>
    );
};


               
export const SaveButtonWithEditionMode = ({ ...props }) => {

    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();    
    const translate = useTranslate();  
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const formState = useFormState();

    const [update, { loading, error }] = useUpdate();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
            
        update(
            { 
                type: 'update', 
                resource: 'events', 
                payload : { id: values.id, data : values }
            },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    redirect('/events');
                    refresh();
                },
                onFailure: ( error ) => {
                    notify(error.message, 'error');
                    setShowDialog(false);
                }
            }
        );
    };    
    
    return (
        <>
            <Button size="medium" variant="contained" onClick={handleClick} label={translate('events.repetitive_mode_edition_save')} startIcon={<Save />} />

            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('remittances.status_form')}
                disableEnforceFocus
            >
                <DialogTitle>{translate('events.repetitive_mode_edition_title_save')}</DialogTitle>

                <FormWithRedirect
                    resource="remittances_users_items"
                    save={handleSubmit}
                    initialValues={{ edition_mode : 0 , ...formState.values }}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>     
                                        <RadioButtonGroupInput label="" row={false} source="edition_mode" choices={[
                                            { id: 0, name: translate('events.repetitive_mode_edition_only_one') },
                                            { id: 1, name: translate('events.repetitive_mode_edition_only_future') },
                                            { id: 2, name: translate('events.repetitive_mode_edition_all') },
                                        ]} />
                                    </Grid> 
                                </Grid>                                                     
                            </DialogContent>
                            <DialogActions>
                                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}


export const DeleteButtonWithEditionMode = ({ ...props }) => {

    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();    
    const translate = useTranslate();  
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const [update, { loading, error }] = useUpdate();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        
        if (window.confirm(translate('events.repetitive_mode_edition_delete_confirmation')))
            if (window.confirm(translate('events.repetitive_mode_edition_delete_confirmation_2')))
                dataProvider.delete('events/' + values.edition_mode , { id: values.id })
                    .then(( { data } ) => {   
                        setShowDialog(false);
                        redirect('/events');
                        refresh();
                    })
                    .catch(error => {     
                        notify(error.message, 'error');
                        setShowDialog(false);
                    });
            else
                setShowDialog(false);
        else
            setShowDialog(false);
            
    };    
    

    return (
        <>
            <Button size="medium" variant="text" onClick={handleClick} label={translate('events.repetitive_mode_edition_delete')} startIcon={<DeleteIcon />} className="event_button_delete" />

            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('remittances.status_form')}
                disableEnforceFocus
            >
                <DialogTitle>{translate('events.repetitive_mode_edition_title_delete')}</DialogTitle>

                <FormWithRedirect
                    resource="remittances_users_items"
                    save={handleSubmit}
                    initialValues={{ edition_mode : 0 , id: props.record.id }}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>     
                                        <RadioButtonGroupInput label="" row={false} source="edition_mode" choices={[
                                            { id: 0, name: translate('events.repetitive_mode_edition_only_one') },
                                            { id: 1, name: translate('events.repetitive_mode_edition_only_future') },
                                            { id: 2, name: translate('events.repetitive_mode_edition_all') },
                                        ]} />
                                    </Grid> 
                                </Grid>                                                     
                            </DialogContent>
                            <DialogActions>
                                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                                    <IconCancel />
                                </Button>
                                <SaveButton 
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                    label={translate('events.repetitive_mode_edition_delete')}
                                    icon={<DeleteIcon />}
                                />   
                                
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}


const FormEvent = props => {
    
    const translate = useTranslate(); 
    const [editDialog, setEditDialog] = useState(false);
    const [createDialog, setCreateDialog] = useState(false);
    const [create, { loading }] = useCreate('events');
    const notify = useNotify();
    const refresh = useRefresh();  
    var redirect = useRedirect();
    const location = useLocation();
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const [cities, setCities] = useState();
                        
    const params = parse(location.search);
    
    
    
    if (params.start && params.end)
    {
        var dt_start = params.start;
        var dt_end = params.end;
    }
    else
    {        
        var now = new Date();      
        var hoursLater = new Date();   
        hoursLater.setMinutes(hoursLater.getMinutes()+2*60);
    
        var dt_start = toIsoString(now);
        var dt_end = toIsoString(hoursLater);
    }
    
    //const today = new Date().toISOString().slice(0, 10);
    const today = dt_start;
                                    
    return (
        <TabbedForm
            {...props}            
            initialValues={{ associations_id: localStorage.getItem('associations_id'), start: dt_start, end: dt_end, repetitive_limit: today , repetitive_days: [], reminder: 0, alert_days: -1 }}
            mutationMode="pessimistic"
            toolbar={<EventToolbar {...props}  />} 
            title=""   
        >        
            <FormTab
                label={translate('events.data')}
                contentClassName={classes.tab}
                path=""   
            > 
            
            <Grid container spacing={1} >
                <Grid item xs={12} sm={7}>
                        
                        
                    <Grid container spacing={1} >
                        <Grid item xs={12} sm={6}>

                            <ReferenceInput 
                                fullWidth 
                                label={translate('events.activity')} 
                                source="activities_id" 
                                reference="activities" 
                                filter={{ }} 
                                perPage={1000}
                                sort={{ field: 'priority', order: 'ASC' }}
                            >
                               <SelectInput optionText="name" optionValue="id" validate={[required()]} />
                            </ReferenceInput>                    
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                            <ClassroomInput />                                
                        </Grid>

                        <ColorInputHidden />

                        <Grid item xs={12} sm={12}>
                            <TextInput fullWidth source="title" autoFocus label={translate('events.name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />                             
                        </Grid>
                                                
                        <Grid item xs={12} sm={12}>
                            <RichTextInput fullWidth source="description" label={translate('common.description')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateTimeInput fullWidth source="start" label={translate('events.start')} validate={[required()]} parse={dateParser} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateTimeInput fullWidth source="end" label={translate('events.end')} validate={[required()]} parse={dateParser} />
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>      
                            <SelectInput fullWidth label={translate('bookings.reminder')} source="reminder" choices={[
                                { id: 0, name: translate('bookings.reminder_0') },
                                { id: 1, name: translate('bookings.reminder_1') },
                                { id: 2, name: translate('bookings.reminder_2') },
                                { id: 3, name: translate('bookings.reminder_3') },
                                { id: 4, name: translate('bookings.reminder_4') },
                                { id: 5, name: translate('bookings.reminder_5') },
                                { id: 6, name: translate('bookings.reminder_6') },
                                { id: 7, name: translate('bookings.reminder_7') },
                                { id: 8, name: translate('bookings.reminder_8') }
                            ]}  />
                        </Grid> 
                        
                        { subscriptionModule(modules.ALERTS)?
                        <Grid item xs={12} sm={6}>      
                            <SelectInput fullWidth label={translate('events.alert_days')} source="alert_days" choices={[
                                { id: -1, name: translate('events.alert_days_none') },
                                { id: 0, name: translate('events.alert_days_0') },
                                { id: 1, name: translate('events.alert_days_1') },
                                { id: 2, name: translate('events.alert_days_2') },
                                { id: 3, name: translate('events.alert_days_3') },
                                { id: 4, name: translate('events.alert_days_4') },
                                { id: 5, name: translate('events.alert_days_5') },
                                { id: 6, name: translate('events.alert_days_6') },
                                { id: 7, name: translate('events.alert_days_7') },
                                { id: 8, name: translate('events.alert_days_8') },
                                { id: 9, name: translate('events.alert_days_9') },
                                { id: 10, name: translate('events.alert_days_10') },
                                { id: 11, name: translate('events.alert_days_11') },
                                { id: 12, name: translate('events.alert_days_12') },
                                { id: 13, name: translate('events.alert_days_13') },
                                { id: 14, name: translate('events.alert_days_14') }
                            ]}  />
                        </Grid> : null }   
                        
                        <Grid item xs={12} sm={12}>
                            <TextInput fullWidth source="tickets_url" autoFocus label={translate('events.tickets_url')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                             
                        </Grid>

                    </Grid>
                                        
                </Grid>
                <Grid item xs={12} sm={1}>
                </Grid>
                <Grid item xs={12} sm={4}>
                
                    <Grid container spacing={1} >
                        
                        <Grid item xs={12} sm={12}>
                            <BooleanInput source="is_public" label={translate('events.is_public')} />
                        </Grid>
                        
                        { subscriptionModule(modules.CALENDAR,modulesOptions.CALENDAR_FAC) || subscriptionModule(modules.CALENDAR,modulesOptions.CALENDAR_FAC_PUBLISHERS) ?
                        <Grid item xs={12} sm={12}>
                            <BooleanInput source="show_fac" label={translate('events.show_fac')} />                            
                        </Grid>
                        : null }
                        
                        { subscriptionModule(modules.CALENDAR,modulesOptions.CALENDAR_FAC_PUBLISHERS) ?                        
                        <Grid item xs={12} sm={12}>
                            <ReferenceInput 
                                fullWidth 
                                label={translate('events.organizer')} 
                                source="organizers_id" 
                                reference="organizers" 
                                filter={{ associations_id: localStorage.getItem('associations_id') }}
                                perPage={1000}
                                sort={{ field: 'name', order: 'ASC' }}
                            >
                                <SelectInput optionText="name" optionValue="id" resettable />                        
                            </ReferenceInput>              
                        </Grid>
                        : null }
                        
                        <Grid item xs={12} sm={12}>
                            <TextInput fullWidth source="address" label={translate('events.address')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                             
                        </Grid>
                        
                        <Grid item xs={12} sm={12}>
                            <CityInput />
                        </Grid>
                                                                        
                        <Grid item xs={12} sm={12} className="events_labels">

                            <ReferenceArrayInput
                                source="labels"
                                reference="labels"
                                filter={{ type: 1 }}
                                perPage={1000}
                                sort={{ field: 'priority', order: 'ASC' }}
                            >
                                <CheckboxGroupInput 
                                    optionText="name" 
                                    optionValue="id"
                                    label={translate('common.labels')}            
                                    row={true}
                                    className="checkboxGroupInputCompacted"  
                                />
                            </ReferenceArrayInput>        

                        </Grid>                

                        <Grid item xs={12} sm={12} className="generate_repetitive mt10" >                        
                            <BooleanInput source="repetitive" label={translate('events.repetitive')} />
                        </Grid>  

                        <FormDataConsumer>  
                            {({ formData, ...rest }) => formData.repetitive == true &&   

                            <>
                            <Grid item xs={12} sm={12} className="generate_repetitive" >             

                            <CheckboxGroupInput label="" source="repetitivedays" choices={[
                                { id: 1, name: translate('common.day_week_1') },
                                { id: 2, name: translate('common.day_week_2') },
                                { id: 3, name: translate('common.day_week_3') },
                                { id: 4, name: translate('common.day_week_4') },
                                { id: 5, name: translate('common.day_week_5') },
                                { id: 6, name: translate('common.day_week_6') },
                                { id: 7, name: translate('common.day_week_7') },
                            ]}  />

                            </Grid> 
                            <Grid item xs={12} sm={12} className="generate_repetitive" > 
                            <DateInput fullWidth source="repetitive_limit" label={translate('events.repetitive_limit')} />
                            </Grid> 
                            </>

                            }                    
                        </FormDataConsumer>  
                    
                    </Grid>  
                                
                </Grid>
                
            </Grid>
                
            </FormTab>  
            
            {props.record && props.record.id ? 
            <FormTab
                label={translate('events.images')}
                contentClassName={classes.tab}
                path="images"                 
            > 
                <DocsList target={targets.EVENT_IMAGE} items_id={props.record.id} mark={true} mark_title={translate('events.docs_mark')} />
                <br /><span>{translate('events.images_desc')}</span>
            </FormTab>
            : null }
        
            {props.record && props.record.id ? 
            <FormTab
                label={translate('events.docs')}
                contentClassName={classes.tab}
                path="docs"                 
            > 
                <DocsList target={targets.EVENT} items_id={props.record.id} mark={true} mark_title={translate('events.docs_mark')} />
                <br /><span>{translate('events.docs_desc')}</span>
            </FormTab>
            : null }
        
            {props.record && props.record.id ? 
            <FormTab
                label={translate('events.bookings')}
                contentClassName={classes.tab}
                path="bookings"                 
            > 
                <BookingsList events_id={props.record.id} />
                
            </FormTab>
            : null }
        
        </TabbedForm>
    )
}

const ClassroomInput = ({ valor, ...props }) => {

    const translate = useTranslate(); 
    const dataProvider = useDataProvider();
    const { change } = useForm();
  
    const handleChangeClassroom = (event) => {
        
        if (event.target && event.target.value && event.target.value > 0)
        {
            const classrooms_id = event.target.value;

            dataProvider.getOne('classrooms', { id: classrooms_id })
            .then(( { data } ) => {   
                change('address', data.address);
                change('cities_id', data.cities_id);
            })
            .catch(error => {                

            });
        }        
    };
  
    return (
      <ReferenceInput 
          fullWidth 
          label={translate('events.classroom')} 
          source="classrooms_id" 
          reference="classrooms" 
          filter={{ associations_id: localStorage.getItem('associations_id'), onlyForEvents: true }}
          perPage={1000}
          sort={{ field: 'priority', order: 'ASC' }}
          onChange={handleChangeClassroom}
      >
          <SelectInput optionText="name" optionValue="id" resettable  />                        
      </ReferenceInput>  
      );
};

export const ColorInputHidden = ({ ...props }) => {
    
    const dataProvider = useDataProvider();
    const { values } = useFormState();
    const [color, setColor] = React.useState(false);      
    
    useEffect(() => {
        
        if (values.classrooms_id)
            dataProvider.getOne('classrooms', { id: values.classrooms_id })
            .then(( { data } ) => {   
                setColor(data.color);
            })
            .catch(error => {                
                setColor(null);
            });
        else
            setColor(null);
        
      }, [values.classrooms_id]);
    
    if (!color) {
        localStorage.removeItem('classrooms_color');        
    }
    else{
        localStorage.setItem('classrooms_color', color );
    }
    return null;
  
}

const useStyles = makeStyles({
    comment: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tab: {
        maxWidth: '100%',
        display: 'block',
    },
});


