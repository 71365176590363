// in src/Dashboard.js
import React, { useState, useEffect } from 'react';




import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';

import { AccordionSection  } from '@react-admin/ra-form-layout';



import {
  ReferenceInput,
  SelectInput,
  TextInput, required, DateInput,NumberInput,BooleanInput, useCreate, useRefresh, FormWithRedirect, SaveButton, useDataProvider,
  useNotify, 
  ArrayInput,
  SimpleFormIterator
} from 'react-admin';

import { validateMaxLength, validateInteger, validateDouble, validatePercent  } from '../components/Validator';
import MyLoading from '../components/MyLoading';


import { parse } from 'query-string';




import { useTranslate  } from '../common/language';
import { choicesClassroomsType, choicesTrainingsType0,choicesTrainingsType1,choicesTrainingsFormalArt,choicesTrainingsFormal, choicesShowsType, choicesAdult } from './../common/constants';

import { ImageViewer } from '../resources/docs';


import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';





export const AnnuaryForm = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
  const [info, setInfo] = useState();
  const [annuaryForm, setAnnuaryForm] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorApi, setErrorApi] = useState();
  const [create] = useCreate('users_annuary/public/form');
  const [formSent, setFormSent] = useState();
    
  const { token: token_string } = parse(props.location.search);
    
  const [open, setOpen] = React.useState(false);

  const openErrorDialog = () => {
    setOpen(true);
  };
  const closeErrorDialog = () => {
    setOpen(false);
  };
    
  useEffect(() => {
    dataProvider.getOne('users_annuary/public/form', { id: token_string })
      .then(( { data } ) => { 
        setAnnuaryForm(data);
        
        dataProvider.getOne('associations/public', { id: data.associations_id })
          .then(( { data } ) => { 
            setInfo(data);
            setLoading(false);
            setError(false);
          })
          .catch(error => {                         
            setLoading(false);  
            setError(true);
          });
      })
      .catch(error => {          
        setLoading(false);  
        setError(true);
      });
  }, []);
    
  const handleSubmit = values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {              
          setFormSent(true);
          setErrorApi('');
        },
        onFailure: ( error ) => {
          setErrorApi(error.message);
          openErrorDialog();                                    
        }
      }
    );
  };
    
  if (loading) return <MyLoading />;
  if (error) return <center><br /><br/>{translate('users_annuary.not_found')}</center>;
  if (!info) return null;
    
  if (formSent)
  {
    return (
      <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
        <Grid container spacing={2}>
          <Grid item xs={12}>  
            <HeaderPage info={info} title="" />
          </Grid>
        </Grid>                   
        <div className="add_member_form_sent" dangerouslySetInnerHTML={{ __html: info.annuary_form_sent }} />                                
      </Box>
    );
  }
    
  let title = translate('users_annuary.form_title').replaceAll('%year%',annuaryForm.year);
                
  if (annuaryForm.expired && !annuaryForm.answered)
    return <center><br /><br/>{translate('users_annuary.expired')}</center>; 
  else
    return (
      <>
        <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
                
          <br/>
          <Grid container spacing={2}>
            <Grid item xs={12}>  
              <HeaderPage info={info} title={title} />
            </Grid>
          </Grid>   
                                                              
          <br />
          <Grid container spacing={2} >
            { annuaryForm.expired && annuaryForm.answered ?
              <div className="" spacing={2} dangerouslySetInnerHTML={{ __html: translate('users_annuary.form_already_answered').replaceAll('%year%',annuaryForm.year) }} />                    
              :
              (!annuaryForm.expired && !annuaryForm.answered) || (!annuaryForm.expired && annuaryForm.answered) ?
                <div className="" spacing={2} dangerouslySetInnerHTML={{ __html: info.annuary_form_text.replaceAll('%year%',annuaryForm.year) }} />
                : null
            }
          </Grid>  
          <br />
          <br />                
          <>
                
            <Grid container spacing={2}>
                
              <FormWithRedirect
                resource=""        
                save={handleSubmit}
                initialValues={annuaryForm}
                render={formProps => (
                  <>   
                    <fieldset disabled={annuaryForm.expired}>
                      <AccordionSection className="accordion" label={translate('users_annuary.form_general_data')} fullWidth defaultExpanded={false}>                           
                        <Grid container spacing={2}>
                          <Grid item xs={12}>                    
                            <TextInput fullWidth source="users_name" label={translate('users_annuary.form_entity')} disabled />
                          </Grid>
                          <Grid item xs={4}>                    
                            <NumberInput fullWidth source="users_demografy.num_members" label={translate('users_demografy.num_members')} />
                          </Grid>
                          <Grid item xs={4}>                    
                            <NumberInput fullWidth source="users_demografy.avg_age" label={translate('users_demografy.avg_age')} />
                          </Grid>
                          <Grid item xs={4}>                    
                            <NumberInput fullWidth source="users_demografy.avg_age_managers" label={translate('users_demografy.avg_age_managers')} />
                          </Grid>
                        </Grid>    
                        <Grid container spacing={2}>
                          <Grid item xs={4}>                    
                            <NumberInput fullWidth source="users_demografy.percent_man" label={translate('users_demografy.percent_man')} validate={[validatePercent(translate('common.validation.number'))]} />
                          </Grid>
                          <Grid item xs={4}>                    
                            <NumberInput fullWidth source="users_demografy.percent_woman" label={translate('users_demografy.percent_woman')} validate={[validatePercent(translate('common.validation.number'))]} />
                          </Grid>
                          <Grid item xs={4}>                    
                            <NumberInput fullWidth source="users_demografy.percent_no_binary" label={translate('users_demografy.percent_no_binary')} validate={[validatePercent(translate('common.validation.number'))]} />
                          </Grid>
                        </Grid>
                      </AccordionSection>
                                
                                
                      <AccordionSection className="accordion" label={translate('users_annuary.form_activities')} fullWidth defaultExpanded={false}> 
                        <span>{translate('users_annuary.form_desc_activities')}</span>
                        <ArrayInput source="users_activities_items" label="" className="array-input" disabled={annuaryForm.expired}>
                          <SimpleFormIterator 
                            disableReordering
                            inline 
                            className="simple-iterator-inline"
                            getItemLabel={index => ''}
                          >
                            <ReferenceInput className="form-250" sort={{ field: 'priority', order: 'ASC' }} perPage={1000} label={translate('users_activities.name')} source="activities_users_id" reference="activities_users">
                              <SelectInput optionText="name" optionValue="id" validate={required()} />
                            </ReferenceInput> 
                            <NumberInput className="form-100" source="quantity" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_activities.quantity')} />
                            <NumberInput className="form-100" source="assistants" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_activities.assistants')} />
                            <SelectInput className="form-200" label={translate('users_shows.adult')} source="adult" choices={choicesAdult(translate)} resettable /> 
                            <TextInput className="form-200" source="comment" validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} label={translate('common.comment')} />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </AccordionSection>
                                
                      <AccordionSection className="accordion" label={translate('users_annuary.form_trainings_art')} fullWidth defaultExpanded={false}>  
                        <span>{translate('users_annuary.form_desc_trainings_art')}</span><br />
                        <NumberInput source="users_trainings_art_hours" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_trainings.total_hours')} />                                    
                        <ArrayInput source="users_trainings_art_items" label="" className="array-input" disabled={annuaryForm.expired}>
                          <SimpleFormIterator 
                            disableReordering
                            inline 
                            className="simple-iterator-inline"
                            getItemLabel={index => ''}
                          >
                            <SelectInput className="form-100" source="type" label={translate('users_trainings.type')} fullWidth validate={required()} choices={choicesTrainingsType0(translate)} />
                            <SelectInput className="form-100" source="formal" label={translate('users_trainings.formal')} fullWidth validate={required()} choices={choicesTrainingsFormalArt(translate)} />
                            <NumberInput className="form-100" source="students" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_trainings.students')} />
                            <NumberInput className="form-100" source="teachers_workers" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_trainings.teachers_workers')} />
                            <NumberInput className="form-100" source="teachers_volunteers" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_trainings.teachers_volunteers')} />
                          </SimpleFormIterator>
                        </ArrayInput>                                    
                      </AccordionSection>
                                
                      <AccordionSection className="accordion" label={translate('users_annuary.form_trainings')} fullWidth defaultExpanded={false}>  
                        <span>{translate('users_annuary.form_desc_trainings')}</span><br />
                        <NumberInput source="users_trainings_hours" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_trainings.total_hours')} />
                        <ArrayInput source="users_trainings_items" label="" className="array-input" disabled={annuaryForm.expired}>
                          <SimpleFormIterator 
                            disableReordering
                            inline 
                            className="simple-iterator-inline"
                            getItemLabel={index => ''}
                          >
                            <SelectInput className="form-100" source="type" label={translate('users_trainings.type')} fullWidth validate={required()} choices={choicesTrainingsType1(translate)} />
                            <SelectInput className="form-100" source="formal" label={translate('users_trainings.formal')} fullWidth validate={required()} choices={choicesTrainingsFormal(translate)} />
                            <NumberInput className="form-100" source="students" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_trainings.students')} />
                            <NumberInput className="form-100" source="teachers_workers" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_trainings.teachers_workers')} />
                            <NumberInput className="form-100" source="teachers_volunteers" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_trainings.teachers_volunteers')} />
                          </SimpleFormIterator>
                        </ArrayInput>                                    
                      </AccordionSection>
                                
                      <AccordionSection className="accordion" label={translate('users_annuary.form_classrooms')} fullWidth defaultExpanded={false}>    
                        <span>{translate('users_annuary.form_desc_classrooms')}</span>
                        <ArrayInput source="users_classrooms" label="" className="array-input" disabled={annuaryForm.expired}>
                          <SimpleFormIterator 
                            disableReordering
                            inline
                            className="simple-iterator-multiline"
                            getItemLabel={index => ''}                     
                          >
                            <SelectInput className="form-250" label={translate('classrooms.type')} source="type" choices={choicesClassroomsType(translate)} validate={[required()]} />
                            <TextInput className="form-200" source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]}  />
                            <NumberInput className="form-100" source="m2" label={translate('classrooms.m2')} validate={[validateInteger(translate('common.validation.number'))]}  />
                            <NumberInput className="form-100" source="seats" label={translate('classrooms.seats')} validate={[validateInteger(translate('common.validation.number'))]}  />
                            <BooleanInput className="form-200 form-clear" source="accessible" label={translate('classrooms.accessible')} />  
                            <BooleanInput className="form-150" source="outdoor" label={translate('classrooms.outdoor')} />   
                            <BooleanInput className="form-250" source="lift" label={translate('classrooms.lift')} />       
                            <BooleanInput className="form-150" source="cession" label={translate('classrooms.cession')} />  
                            <BooleanInput className="form-150" source="rent" label={translate('classrooms.rent')} />       
                            <BooleanInput className="form-250" source="rentable" label={translate('classrooms.rentable')} />                                   
                          </SimpleFormIterator>
                        </ArrayInput>
                      </AccordionSection>
                                
                      <AccordionSection className="accordion" label={translate('users_annuary.form_shows')} fullWidth defaultExpanded={false}> 
                        <span>{translate('users_annuary.form_desc_shows')}</span>
                        <ArrayInput source="users_shows_items" label="" disabled={annuaryForm.expired}>
                          <SimpleFormIterator 
                            disableReordering
                            inline
                            className="simple-iterator-multiline"
                            getItemLabel={index => ''}                     
                          >
                            <SelectInput className="form-200" label={translate('users_shows.type')} source="type" choices={choicesShowsType(translate)} validate={[required()]} />
                            <SelectInput className="form-200" label={translate('users_shows.amateur')} source="amateur" choices={[                        
                              { id: 1, name: translate('users_shows.amateur_1')  },
                              { id: 2, name: translate('users_shows.amateur_2')  }
                            ]} resettable />
                            <SelectInput className="form-200" label={translate('users_shows.adult')} source="adult" choices={choicesAdult(translate)} resettable />                    
                            <NumberInput className="form-100" source="quantity" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_shows.quantity')} />
                            <NumberInput className="form-100" source="assistants" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_shows.assistants')} />
                            <NumberInput className="form-100" source="inversion" validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_shows.inversion')} />
                            <NumberInput className="form-100" source="occupancy" validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_shows.occupancy')} />
                            <TextInput className="form-200" source="comment" validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} label={translate('common.comment')} />

                          </SimpleFormIterator>
                        </ArrayInput>
                      </AccordionSection>
                                
                      <AccordionSection className="accordion" label={translate('users_annuary.form_sections')} fullWidth defaultExpanded={false}> 
                        <span>{translate('users_annuary.form_desc_sections')}</span>
                        <ArrayInput source="users_sections_items" label="" disabled={annuaryForm.expired}>
                          <SimpleFormIterator 
                            disableReordering
                            inline
                            className="simple-iterator-inline" 
                            getItemLabel={index => ''}                     
                          >
                            <ReferenceInput sort={{ field: 'priority', order: 'ASC' }}  perPage={1000} label={translate('users_sections.name')} source="sections_users_id" reference="sections_users" className="mt-10">
                              <SelectInput className="form-250" optionText="name" optionValue="id" validate={required()} />
                            </ReferenceInput> 
                            <NumberInput className="form-150" source="participants" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_sections.participants')} />
                            <TextInput className="form-200" source="comment" validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} label={translate('common.comment')} />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </AccordionSection>
                                                         
                      <AccordionSection className="accordion" label={translate('users_annuary.form_workers')} fullWidth defaultExpanded={false}> 
                        <span>{translate('users_annuary.form_desc_workers')}</span><br />
                        <Grid container spacing={2}>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.administration')} fullWidth source="users_workers.administration" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                                                                
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.activities')}   fullWidth source="users_workers.activities" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.concierge')} fullWidth source="users_workers.concierge" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.clean_up')}  fullWidth source="users_workers.clean_up" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.comunication')} fullWidth source="users_workers.comunication" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.accounting')} fullWidth source="users_workers.accounting" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.maintenance')} fullWidth source="users_workers.maintenance" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.management')} fullWidth source="users_workers.management" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.others')} fullWidth source="users_workers.others" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                                                                
                          </Grid>
                        </Grid>
                      </AccordionSection>
                                
                      <AccordionSection className="accordion" label={translate('users_annuary.form_volunteers')} fullWidth defaultExpanded={false}> 
                        <span>{translate('users_annuary.form_desc_volunteers')}</span><br />
                        <Grid container spacing={2}>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.administration')} fullWidth source="users_volunteers.administration" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                                                                
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.activities')}   fullWidth source="users_volunteers.activities" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.concierge')} fullWidth source="users_volunteers.concierge" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.clean_up')}  fullWidth source="users_volunteers.clean_up" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.comunication')} fullWidth source="users_volunteers.comunication" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.accounting')} fullWidth source="users_volunteers.accounting" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.maintenance')} fullWidth source="users_volunteers.maintenance" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.management')} fullWidth source="users_volunteers.management" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid>
                          <Grid item xs={3}>                    
                            <NumberInput label={translate('users_workers.others')} fullWidth source="users_volunteers.others" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                                                                
                          </Grid>
                        </Grid>
                      </AccordionSection>
                                
                      <AccordionSection className="accordion" label={translate('users_annuary.form_social_nets')} fullWidth defaultExpanded={false}>
                        <span>{translate('users_annuary.form_desc_social_nets')}</span><br />
                        <Grid container spacing={2}>
                          <Grid item xs={6}>                    
                            <TextInput fullWidth source="web" label={translate('users.web')} />
                          </Grid>
                        </Grid>                                
                        <ArrayInput source="users_social_nets" label={translate('users_annuary.form_social_nets_title')} disabled={annuaryForm.expired}>
                          <SimpleFormIterator 
                            disableReordering
                            inline
                            className="simple-iterator-inline" 
                            getItemLabel={index => ''}                     
                          >
                            <ReferenceInput label={translate('social_nets.title')} source="nets_id" reference="nets" perPage={1000} >
                              <SelectInput fullWidth optionValue="id" validate={required()} optionText="name" />
                            </ReferenceInput> 
                            <TextInput fullWidth source="link" label={translate('social_nets.link')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </AccordionSection>
        
                      <AccordionSection className="accordion" label={translate('users_annuary.form_economic_data')} fullWidth defaultExpanded={false}> 
                        <span>{translate('users_annuary.form_desc_economic_data')}</span><br />
                        <Grid container spacing={2}>
                          <Grid item xs={4}>                    
                            <NumberInput fullWidth source="users_economic_data.avg_quote" label={translate('users_economic_data.avg_quote')} validate={[validateDouble(translate('common.validation.number'))]} />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>  
                            <FormTitle name={translate('users_annuary.form_economic_data_incomings')} />     
                            <NumberInput label={translate('users_economic_data.members')}  fullWidth source="users_economic_data.members" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.services')} fullWidth source="users_economic_data.services" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.subsidies')}   fullWidth source="users_economic_data.subsidies" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.rentals')}  fullWidth source="users_economic_data.rentals" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.products')} fullWidth source="users_economic_data.products" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.privates')} fullWidth source="users_economic_data.privates" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.others')} fullWidth source="users_economic_data.others" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                          </Grid> 
                          <Grid item xs={1}>  
                          </Grid> 
                          <Grid item xs={3}> 
                            <FormTitle name={translate('users_annuary.form_economic_data_expenses')} /> 
                            <NumberInput label={translate('users_economic_data.exp_rentals')} fullWidth source="users_economic_data.exp_rentals" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.exp_workers')}  fullWidth source="users_economic_data.exp_workers" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.exp_material')}  fullWidth source="users_economic_data.exp_material" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.exp_professionals')}   fullWidth source="users_economic_data.exp_professionals" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.exp_groups')}   fullWidth source="users_economic_data.exp_groups" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.exp_supplies')}  fullWidth source="users_economic_data.exp_supplies" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.exp_maintenance')} fullWidth source="users_economic_data.exp_maintenance" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.exp_marketing')} fullWidth source="users_economic_data.exp_marketing" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.exp_others')}     fullWidth source="users_economic_data.exp_others" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        

                          </Grid>
                          <Grid item xs={1}>  
                          </Grid> 
                          <Grid item xs={3}>  
                            <FormTitle name={translate('users_annuary.form_economic_data_inversion')} />     
                            <NumberInput label={translate('users_economic_data.inv_building')}  fullWidth source="users_economic_data.inv_building" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.inv_theatre')} fullWidth source="users_economic_data.inv_theatre" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.inv_bar')} fullWidth source="users_economic_data.inv_bar" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.inv_accessibility')} fullWidth source="users_economic_data.inv_accessibility" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.inv_security')} fullWidth source="users_economic_data.inv_security" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
                            <NumberInput label={translate('users_economic_data.inv_others')} fullWidth source="users_economic_data.inv_others" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        

                          </Grid> 
                        </Grid>
                      </AccordionSection>
                                
                      <AccordionSection className="accordion" label={translate('users_annuary.form_edited_books_books')} fullWidth defaultExpanded={false}> 
                        <span>{translate('users_annuary.form_desc_edited_books_books')}</span>
                        <ArrayInput source="users_edited_books_books" label="" className="array-input" disabled={annuaryForm.expired}>
                          <SimpleFormIterator 
                            disableReordering
                            inline
                            className="simple-iterator-inline"
                            getItemLabel={index => ''}
                          >
                            <TextInput className="form-250" source="name" label={translate('common.name')} validate={[required(), validateMaxLength(100,translate('common.validation.maxLength'))]} />   
                            <DateInput className="form-200" source="date_publication" label={translate('users_federation.edited_books_date')} validate={[required()]} />                                    
                          </SimpleFormIterator>
                        </ArrayInput>
                      </AccordionSection>
                                
                      <AccordionSection className="accordion" label={translate('users_annuary.form_edited_books_newsletters')} fullWidth defaultExpanded={false}>  
                        <span>{translate('users_annuary.form_desc_edited_books_newsletters')}</span>
                        <ArrayInput source="users_edited_books_newsletters" label="" className="array-input" disabled={annuaryForm.expired}>
                          <SimpleFormIterator 
                            disableReordering
                            inline 
                            className="simple-iterator-inline"
                            getItemLabel={index => ''}
                          >
                            <TextInput className="form-250" source="name" label={translate('common.name')} validate={[required(), validateMaxLength(100,translate('common.validation.maxLength'))]} />                                       
                            <TextInput className="form-200" source="period" label={translate('users_federation.edited_books_period')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />   
                          </SimpleFormIterator>
                        </ArrayInput>
                      </AccordionSection>
                                
                      <AccordionSection className="accordion" label={translate('users_annuary.form_edited_books_magazines')} fullWidth defaultExpanded={false}>  
                        <span>{translate('users_annuary.form_desc_edited_books_magazines')}</span>
                        <ArrayInput source="users_edited_books_magazines" label="" className="array-input" disabled={annuaryForm.expired}>
                          <SimpleFormIterator 
                            disableReordering
                            inline 
                            className="simple-iterator-inline"
                            getItemLabel={index => ''}
                          >
                            <TextInput className="form-250" source="name" label={translate('common.name')} validate={[required(), validateMaxLength(100,translate('common.validation.maxLength'))]} />                                       
                            <TextInput className="form-200" source="period" label={translate('users_federation.edited_books_period')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />   
                          </SimpleFormIterator>
                        </ArrayInput>
                      </AccordionSection>
                
                      {!annuaryForm.expired ?
                        <div className="add_member_lopd" dangerouslySetInnerHTML={{ __html: info.annuary_form_lopd.replaceAll('%year%',annuaryForm.year) }} />
                        : null }

                      {!annuaryForm.expired ?
                        <div className="add_member_button" >
                          <SaveButton
                            saving={formProps.saving}
                            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                            label={translate('users_annuary.form_send')}
                            icon={<span />}
                          />
                        </div>
                        : null }
                    </fieldset>

                    <br />

                  </>


                )}
              />
            </Grid> 
          </>                
        </Box>
          
        <BootstrapDialog
          onClose={closeErrorDialog}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {translate('users_annuary.title')}   
            <IconButton
              aria-label="close"
              onClick={closeErrorDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Typography gutterBottom>
              {errorApi}
            </Typography>
          </DialogContent>
        </BootstrapDialog>
          
      </>
    );
};

const FormTitle = ({...props}) => {
  return (
    <Grid container spacing={1} >
      <Grid item xs={12} sm={12}>
        <Typography variant="h6" className="grey8 mt-30 bt-2-grey" align="">
          {props.name}
        </Typography>
        <br />
      </Grid>
    </Grid>   
  );
};

export const HeaderPage = ({info,title,users_name,...props}) => {

  return ( 
    <>
      { info.annuary_form_logo ? 
        <>
          { info.image > 0 ? 
            <center><ImageViewer docs_id={info.image} className="docs_logo_add_member" is_public={true} /></center>
            : 
            <Typography variant="h4" className="grey8" align="center">
              <br/>{info.name}          
            </Typography>
          } 
          <br />           
          <Typography variant="h6" className="grey8" align="center">              
            {title}
          </Typography>
          <br /><br />
        </>
        : null }
    </>
  );
};



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
